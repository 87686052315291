<template>
  <div class="goodsOptionListContainer">
    <div class="optionHead">
      <ul class="flexAndCenter color333 fontSize14">
        <li class="optionSelect">{{ $fanyi("オプション选择") }}</li>
        <li class="num">{{ $fanyi("数量") }}</li>
        <li class="estimatedCost">{{ $fanyi("予想費用") }}</li>
        <li class="subtotal">{{ $fanyi("小計") }}</li>
      </ul>
    </div>
    <!-- 单个商品 -->
    <div class="list">
      <ul v-for="(listItem, listIndex) in list" :key="listIndex" class="flexAndCenter color333"
          style="background: transparent">
        <!--        オプション选择-->
        <li class="optionSelect flexAndCenter">
          <div class="check">
            <input type="checkbox" @change="updateOptionNum(listItem)"
                   v-model="listItem.checked"/>
          </div>
          <div style="margin-left: 13px;text-align: left;line-height: normal">{{
              listIndex + 1
            }}）{{ listItem.name_translate }}
          </div>
        </li>
        <!--        数量-->
        <li class="num" style="justify-content: center;display: flex">
          <el-input v-model="listItem.num" size="small" style="width: 80px;"
                    @input="updateOptionTotalPrice(listItem)"
                    @wheel.native.prevent="stopScroll($event)"
                    @keydown.native="newChannelInputLimit"/>
        </li>
        <!-- 予想費用 -->
        <li class="estimatedCost flexAndCenter" style="justify-content: center">
          <el-input v-model="listItem.price" disabled size="small" style="width: 80px;"/>
          <div style="margin-left: 10px">{{ listItem.unit }}</div>
        </li>
        <!-- 小計 -->
        <li class="subtotal" style="text-align: center">
          {{ Number(listItem.total).toFixed(2) }}
        </li>
      </ul>
    </div>
    <div class="optionTotalPriceContainer flexAndCenter">
      <div class="color333">{{ $fanyi("合计（元）") }}：</div>
      <div>{{ newOptionPrice.toFixed(2) }}</div>
    </div>
    <span slot="footer" class="dialog-footer">
       <el-button @click="resetGoodsOption">{{ $fanyi("重置") }}</el-button>
       <el-button type="primary" @click="saveGoodsOption">{{ $fanyi("保存") }}</el-button>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    list: {//option集合
      type: Array
    },
    orderList: {//订单数据
      type: Array
    },
    itemIndex: {//订单id
      type: Number
    },
    option: {//默认option集合
      type: Array
    },
    optionPrice: {//option价格
      type: Number
    },
    timer: {//刷新子组件
      type: String
    },
    orderStatus: {//订单状态
      type: String
    }
  },
  data() {
    return {
      newOptionPrice: this.optionPrice,
    }
  },
  created() {
    this.$forceUpdate();
  },
  methods: {
    //重置商品的附加服务
    resetGoodsOption() {
      this.newOptionPrice = 0;
      this.list.forEach((item) => {
        item.checked = false;
        item.num = 0;
        item.total = 0;
      })
    },
    //保存商品的附加服务
    saveGoodsOption() {
      this.newOptionPrice = 0;
      this.$emit("saveGoodsOption", this.list);
    },
    //防止输入框滚动增值
    stopScroll(evt) {
      evt = evt || window.event;
      if (evt.preventDefault) {
        // Firefox
        evt.preventDefault();
        evt.stopPropagation();
      } else {
        // IE
        evt.cancelBubble = true;
        evt.returnValue = false;
      }
      return false;
    },
    //输入框校验
    newChannelInputLimit(e) {
      let key = e.key;
      // 不允许输入'e'、'.'、'。'、'-'
      if (key === "e" || key === "." || key === "。" || key == "-" || key == "-" || key == "-") {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    //更新option选中的商品的总价
    updateOptionTotalPrice(val) {
      val.num = this.$fun.priceReplace(val.num);
      this.commodityTotal();
    },
    //根据当前订单的番数更新option选中的数量
    updateOptionNum(item) {
      if (item.checked === true) {
        this.orderList.forEach((newListItem) => {
          if (this.itemIndex === newListItem.id) {
            item.num = newListItem.num;
          }
        })
      } else {
        this.orderList.forEach((newListItem) => {
          if (this.itemIndex === newListItem.id) {
            item.num = 0;
            item.total = 0;
          }
        })
      }
      this.commodityTotal();
    },
    commodityTotal() {
      this.newOptionPrice = 0;
      this.list.forEach((item) => {
        if (item.price_type !== 1 && item.checked === true) {
          this.orderList.forEach((newListItem) => {
            if (this.itemIndex === newListItem.id) {
              if (item.name !== '配套' && item.name !== '拍照' && item.name !== '单面印刷' && item.name !== '双面印刷') {
                if (newListItem.num < item.num) {
                  item.num = newListItem.num;
                }
              }
              this.newOptionPrice += item.num * Number(item.price);
              item.total = item.num * Number(item.price);
            }
          })
        }
        if (item.price_type === 1 && item.checked === true) {
          this.orderList.forEach((newListItem) => {
            if (this.itemIndex === newListItem.id) {
              if (newListItem.num < item.num) {
                item.num = newListItem.num;
              }
              this.newOptionPrice += (Number(newListItem.price) * newListItem.num) * (item.price / 100);
              item.total = (Number(newListItem.price) * newListItem.num) * (item.price / 100);
            }
          })
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../../../css/mixin.scss";
@import "../css/orderDetails.scss";

input[type="checkbox"]:checked {
  background: #4A91E9;
  border: none;
}

.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: 120px;
    height: 34px;
    border-radius: 8px;
    padding: 0;
    font-size: 14px;
  }

  button:first-child {
    border: 1px solid #B4272B;
    color: #B4272B;
  }
}
</style>
