<template>
  <div>
    <HomePageTop ref="tyop"></HomePageTop>
    <div element-loading-text=""
         element-loading-background="rgba(0, 0, 0, 0.3)"
         class="orderPage"
         v-loading.fullscreen.lock="fullscreenLoading">
      <div class="main zhu base-w">
        <!-- 头部 -->
        <div class="head">
        <span class="flexAndCenter"><font class="headT1">{{ $fanyi("注文NO") }}</font><span
            class="headT1">:{{ datas.order_sn }}</span></span>
          <div class="flexAndCenter">
            <div class="headerSearchContainer flexAndCenter">
              <div class="inputContainer flexAndCenter">
                <el-input v-model="input" :placeholder="$fanyi('请输入想搜索的URL')" style="width:340px"/>
              </div>
              <div class="searchBtn" @click="linkSearch">{{ $fanyi('搜索') }}</div>
            </div>
            <div class="newSearchBtn" @click="resetLinkSearch">{{ $fanyi('重置') }}</div>
          </div>
        </div>
        <!-- 订单列表 -->
        <div class="order">
          <ul>
            <li class="inputBox">{{ $fanyi("选择") }}</li>
            <li class="inputBox">No</li>
            <li class="mirror">{{ $fanyi("写真") }}</li>
            <li class="goods">{{ $fanyi("商品规格") }}</li>
            <li class="quantityOrdered">{{ $fanyi("订购数量") }}</li>
            <li class="quantityOrdered"><span>{{ $fanyi("单价(元)") }}</span></li>
            <li class="quantityOrdered">{{ $fanyi("小计(元)") }}</li>
            <!-- 调查在库数量 -->
            <li class="price">{{ $fanyi("选项") }}</li>
            <li class="options">{{ $fanyi("标签设置") }}</li>
            <li class="remark">{{ $fanyi("备考栏") }}</li>
          </ul>
          <div class="goodsBox">
            <ul v-for="(item,itemIndex) in newList" :key="item.id" style="background: white;height:120px">
              <li class="inputBox" @click="item.checked = !item.checked;radio(itemIndex);">
                <input type="checkbox" style="margin:0" @change="radio(itemIndex)" v-model="item.checked"
                />
              </li>
              <li class="inputBox" style="padding-top: 0">
                <div style="margin-top: 0">
                  {{ itemIndex + 1 }}
                </div>
                <el-input v-model="item.for_sn" :placeholder="$fanyi('管理番号')" size="mini" style="margin-top:-30px"/>
              </li>
              <!--            写真编辑-->
              <li :class="item.mirrorLeftIcon===true?'mirror mirrorActive':'mirror'"
                  @mouseenter="mirrorMouseenter(itemIndex)"
                  @mouseleave="mirrorMouseleave(itemIndex)">
                <div style="display: flex;justify-content: flex-end" v-show="item.mirrorLeftIcon===false">
                  <div style="width: 26px;height: 16px;justify-content: flex-end" class="flexAndCenter">
                    <img src="../../../../../assets/redEditIcon.png" alt="" style="width:12px;height:12px">
                  </div>
                </div>
                <div style="display: flex;justify-content: flex-end" v-show="item.mirrorLeftIcon===true">
                  <div style="width: 26px;height: 16px;background: #B4272B;justify-content: center"
                       class="flexAndCenter">
                    <img src="../../../../../assets/baiEditIcon.png" alt="" style="width:12px;height:12px">
                  </div>
                </div>
                <el-upload
                    class="upload-demo"
                    :action="uploadUrl"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    name="file"
                    v-if="$route.query"
                    slot="reference"
                    accept=".jpg,.jpeg,.png"
                    :before-upload="beforeAvatarUpload"
                >
                  <el-popover placement="right" trigger="hover">
                    <el-image :src="item.pic"
                              @click="$fun.toCommodityDetails(item.goods_id,item.from_platform)"
                              style="width: 300px; height: 300px"
                    />
                    <el-image @click="getItemMessage(item)"
                              :src="item.pic"
                              slot="reference"
                              style="width: 60px;height: 60px"
                    />
                  </el-popover>
                </el-upload>
                <el-popover placement="bottom" trigger="hover">
                  <div>{{ $fanyi("手续费") }}：{{
                      $fun.returnServiceRate(item.service_rate)
                    }}%
                  </div>
                  <div slot="reference">{{ item.from_platform }}</div>
                </el-popover>
              </li>
              <!-- 商品详细编辑 -->
              <li style="padding:0 0 0 29px;margin:0 3px"
                  :class="item.goodsLeftIcon===true?'goods mirrorActive':'goods'"
                  @mouseenter="goodsMouseenter(itemIndex)"
                  @mouseleave="goodsMouseleave(itemIndex)"
                  @click="getGoodsDetail(item.detail,item,itemIndex)"
                  @mousedown="goodsMouseenter(itemIndex)">
                <div style="display: flex;justify-content: flex-end;width: 100%" v-show="item.goodsLeftIcon===false">
                  <div style="width: 26px;height: 16px;" class="flexAndCenter">
                    <img src="../../../../../assets/redEditIcon.png" alt="" style="width:12px;height:12px">
                  </div>
                </div>
                <div style="display: flex;justify-content: flex-end;width: 100%" v-show="item.goodsLeftIcon===true">
                  <div style="width: 26px;height: 16px;background: #B4272B;justify-content: center"
                       class="flexAndCenter">
                    <img src="../../../../../assets/baiEditIcon.png" alt="" style="width:12px;height:12px">
                  </div>
                </div>
                <div class="showDetail">
                  <el-popover trigger="hover" placement="top">
                    <div class="showDetailCon u-line">
                      <p v-for="(bitem, bindex) in item.detail"
                         :key="bitem.key + bindex"
                         :title="bitem.key + ':' + bitem.value"
                         class="goodsDetailOpt ispay">
                        {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                      </p>
                    </div>
                    <div class="showDetailCon u-line" slot="reference">
                      <p v-for="(bitem, bindex) in item.detail"
                         :key="bitem.key + bindex"
                         :title="bitem.key + ':' + bitem.value"
                         class="goodsDetailOpt u-line ispay">
                        {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                      </p>
                    </div>
                  </el-popover>
                </div>
              </li>
              <!-- 発注数 -->
              <li class="quantityOrdered" :class="item.detailWindow == true? 'active goodsDetail': 'goodsDetail'"
                  style="margin-top: 35px">
                <el-input @input="numTrunc(item)"
                          @wheel.native.prevent="stopScroll($event)"
                          v-model="item.num"
                          size="mini" @keydown.native="newChannelInputLimit" style="width: 60px;"/>
              </li>
              <!-- 单价 -->
              <li class="quantityOrdered" style="margin-top: 37px">
                <span class="priceText">{{ item.price }}</span>
              </li>
              <!-- 小计 -->
              <li class="quantityOrdered" style="display: flex;flex-direction: column;align-items: center;">
                <span class="priceText">{{ $fun.ceil(item.totalPrice) }}</span>
                <el-popover trigger="hover">
                  <div>
                    <div class="flexAndCenter" style="justify-content: space-between;width: 100%;margin-bottom: 10px">
                      <div>{{ $fanyi("商品金額.元") }}</div>
                      <div>
                        {{
                          Number(item.num * item.price).toFixed(2)
                        }}
                      </div>
                    </div>
                    <div class="flexAndCenter" style="justify-content: space-between;width: 100%;margin-bottom: 10px">
                      <div>{{ $fanyi('手数料') }}({{ $fun.returnServiceRate(item.service_rate) }}%).{{
                          $fanyi('元')
                        }}
                      </div>
                      <div>{{ Number(item.goodsServiceCharge).toFixed(2) }}</div>
                    </div>
                    <div class="flexAndCenter" style="justify-content: space-between;width: 100%;margin-bottom: 10px">
                      <div>{{ $fanyi("オプション手数料.元") }}</div>
                      <div>{{ Number(item.optionPrice).toFixed(2) }}</div>
                    </div>
                  </div>
                  <div class="quantityOrderedDetailsText" slot="reference">{{ $fanyi('详细') }}</div>
                </el-popover>
              </li>
              <!-- option编辑 -->
              <li :class="item.priceLeftIcon===true?'price mirrorActive':'price'"
                  style="max-width: 200px;width:200px;cursor: pointer;"
                  @mouseenter="priceMouseenter(itemIndex)"
                  @click="showGoodsOption(item,itemIndex)"
                  @mouseleave="priceMouseleave(itemIndex)"
                  @mousedown="priceMouseenter(itemIndex)">
                <div style="display: flex;justify-content: flex-end;width: 100%" v-show="item.priceLeftIcon===false">
                  <div style="width: 26px;height: 16px;" class="flexAndCenter">
                    <img src="../../../../../assets/redEditIcon.png" alt="" style="width:12px;height:12px">
                  </div>
                </div>
                <div class="priceLeftIconContainer" v-show="item.priceLeftIcon===true">
                  <div class="flexAndCenter riceLeftIconHover">
                    <img src="../../../../../assets/baiEditIcon.png" alt="">
                  </div>
                </div>
                <el-popover trigger="hover" placement="top" v-show="item.optionChecked">
                  <div style="width: 300px" class="u-line">
                    <div v-for="(optionItem,optionIndex) in item.option" :key="optionIndex"
                         style="text-align: left;width: 300px">
                      <div v-if="optionItem.num>0&&optionItem.checked===true">
                        {{ optionIndex + 1 }}.{{ optionItem.name_translate }}* {{ optionItem.num }}({{
                          optionItem.price
                        }}{{ optionItem.unit }}/点)
                      </div>
                    </div>
                  </div>
                  <div slot="reference">
                    <div class="optionHiddenContainer" style="width:150px">
                      <div v-for="(optionItem,optionIndex) in item.option" :key="optionIndex">
                        <div v-if="optionItem.num>0&&optionItem.checked===true" style="text-align: left;width:150px"
                             class="u-line">
                          {{ optionIndex + 1 }}.{{ optionItem.name_translate }} *{{ optionItem.num }}({{
                            optionItem.price
                          }}{{ optionItem.unit }}/点)
                        </div>
                      </div>
                    </div>
                    <div v-show="item.optionCheckedNum>3" style="text-align:left">........</div>
                  </div>
                </el-popover>
              </li>
              <!-- 标签编辑 -->
              <li :class="item.optionsLeftIcon===true?'mirrorActive options':'options'"
                  v-if="orderStatus==='temporary'"
                  @mouseenter="optionsMouseenter(itemIndex)"
                  @mouseleave="optionsMouseleave(itemIndex)"
                  @click="getGoodsTag(item,itemIndex)"
                  @mousedown="optionsMouseenter(itemIndex)" style="margin:0 3px">
                <div style="display: flex;justify-content: flex-end;width: 100%;" v-show="item.optionsLeftIcon===false">
                  <div style="width: 26px;height: 16px;" class="flexAndCenter">
                    <img src="../../../../../assets/redEditIcon.png" alt="" style="width:12px;height:12px">
                  </div>
                </div>
                <div style="display: flex;justify-content: flex-end;width: 100%;"
                     v-show="item.optionsLeftIcon===true">
                  <div style="width: 26px;height: 16px;background: #B4272B;justify-content: center"
                       class="flexAndCenter">
                    <img src="../../../../../assets/baiEditIcon.png" style="width:12px;height:12px">
                  </div>
                </div>
                <div>
                  <el-popover trigger="hover" v-show="item.order_detail_tag.length>0">
                    <div class="orderTagPopoverShowContainer">
                      <div class="tagList" v-for="(tagItem,tagIndex) in item.order_detail_tag" :key="tagIndex">
                        <div v-show="tagItem.no!==''||tagItem.goods_no!==''">
                          <div class="color333 fontSize14">{{ tagItem.type_translate }}</div>
                          <div class="color333 fontSize14">{{ tagItem.no }}</div>
                          <div class="color333 fontSize14">{{ tagItem.goods_no }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="orderTagContainer" slot="reference">
                      <div class="tagList" v-for="(tagItem,tagIndex) in item.order_detail_tag" :key="tagIndex">
                        <div v-show="tagItem.no!==''||tagItem.goods_no!==''">
                          <div class="color333 fontSize14">{{ tagItem.type_translate }}</div>
                          <div class="color333 fontSize14">{{ tagItem.no }}</div>
                          <div class="color333 fontSize14">{{ tagItem.goods_no }}</div>
                        </div>
                      </div>
                    </div>
                  </el-popover>
                </div>
              </li>
              <!-- 备注 -->
              <li class="remark" style="max-width: 200px;margin-top: 10px">
                <textarea v-model="item.client_remark" :disabled="orderStatus!=='temporary'"></textarea>
              </li>
            </ul>
          </div>
          <!--        底部操作栏-->
          <SpecialNoteVue :goodsTotalPrice="goodsTotalPrice" :goodsTotalServiceCharge="goodsTotalServiceCharge"
                          :optionTotalPrice="optionTotalPrice" :RMBTotalPrice="RMBTotalPrice"
                          :goodsTotalServiceChargeDetailsList="goodsTotalServiceChargeDetailsList"
                          :yenTotalPrice="yenTotalPrice" :orderStatus="orderStatus" :selectOptionList="selectOptionList"
                          ref="SpecialNote"/>
          <div class="goodsOptionListContainer">
            <!--          option编辑-->
            <el-dialog
                :title="$fanyi('オプション')"
                :visible.sync="showGoodsOptionDialogVisible"
                width="700px"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                center>
              <saveOption :list="showGoodsOptionList" :key="timer" :orderList="newList" :itemIndex="itemIndex"
                          :option="optionList" :orderStatus="orderStatus"
                          @saveGoodsOption="saveGoodsOption" :optionPrice="optionPrice"/>
            </el-dialog>
            <newOption :key="timer" :itemIndex="itemIndex" :list="showGoodsOptionList" :option="optionList"
                       :optionPrice="optionPrice" :orderList="newList"
                       :orderStatus="orderStatus" @saveGoodsOption="saveGoodsOption"/>
          </div>
          <div class="goodsAttributeEditContainer">
            <el-dialog
                :title="$fanyi('属性编辑')"
                :visible.sync="goodsDetailDialogVisible"
                width="516px"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                center>
              <goodsAttributeEdit :key="timer" :goodsDetailList="goodsDetailList"
                                  @saveGoodsDetail="saveGoodsDetail" ref="goodsAttributeEditDialog"
                                  @hideGoodsDetailDialogVisible="hideGoodsDetailDialogVisible"/>
            </el-dialog>
          </div>
          <div class="holdOrderDialogVisibleContainer">
            <!--          临时保存提示-->
            <el-dialog
                :visible.sync="holdDialogVisible"
                width="360px"
                :show-close="false"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                center>
              <div class="holdOrderContainer">
                <div class="holdOrderHeader">
                  {{ $fanyi('提示') }}
                </div>
                <div class="holdOrderText">{{ $fanyi('一時保存しました。') }}</div>
                <div slot="footer" class="dialog-footer"
                     style="display: flex;padding-bottom:20px;align-items: center;justify-content: center">
                  <el-button @click="holdDialogVisible = false">{{ $fanyi('リセット') }}</el-button>
                  <el-button type="primary" @click="OrdersSubmitted('save');holdDialogVisible=false">
                    {{ $fanyi('保 存') }}
                  </el-button>
                </div>
              </div>
            </el-dialog>
            <el-dialog
                :visible.sync="deleteGoodsDialogVisible"
                width="360px"
                :show-close="false"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                center>
              <div class="holdOrderContainer">
                <div class="holdOrderHeader">
                  {{ $fanyi('提示') }}
                </div>
                <div class="holdOrderText">{{ $fanyi('是否确认删除选中的商品') }}。</div>
                <div slot="footer" class="dialog-footer"
                     style="display: flex;padding-bottom:20px;align-items: center;justify-content: center">
                  <el-button @click="deleteGoodsDialogVisible=false">{{ $fanyi('取消') }}</el-button>
                  <el-button type="primary" @click="delOrder">
                    {{ $fanyi('确定') }}
                  </el-button>
                </div>
              </div>
            </el-dialog>
          </div>
          <!--        tag编辑-->
          <div class="goodsAttributeEditContainer">
            <el-dialog
                :visible.sync="selectTagDialogVisible"
                width="1030px"
                :title="$fanyi('标签设置')"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                center>
              <editTag @saveGoodsTag="saveGoodsTag" :fullscreenLoading="newFullscreenLoading"
                       :key="timer" ref="editTag" :goodsInfo="goodsInfo" :list="order_detail_tag"/>
            </el-dialog>
          </div>
        </div>
      </div>
      <div class="operatingOptions">
        <div class="choies">
          <input
              type="checkbox"
              v-model="checked"
              @change="allChoice"
          />
          <button class="qx" @click="checked = !checked;allChoice();">
            {{ $fanyi("全选") }}
          </button>
          <div class="deleteText" @click="showDeleteGoodsDialogVisible">{{ $fanyi("删除") }}</div>
          <el-popover placement="top-start" trigger="hover" width="358">
            <p class="caoZuoAnNiu">
              <button
                  class="addgoods"
                  @click="showAddCartGoodsToOrderDialog(1)">
                {{ $fanyi("从购物车中导入更多产品") }}
              </button>
            </p>
            <p class="caoZuoAnNiu">
              <button class="addLibraryOfGoods" @click="showAddCartGoodsToOrderDialog(2)">
                {{ $fanyi("从商品库中导入更多产品") }}
              </button>
            </p>
            <div class="AdditionalBtn" slot="reference">{{ $fanyi("追加") }}</div>
          </el-popover>
          <div class="goodsNum flexAndCenter">{{ $fanyi("商品数") }}
            <div>{{ goodsTotalNum }}</div>
            {{ $fanyi("件") }}
          </div>
          <div>{{ $fanyi("商品金额") }}：<span class="newPrice">{{ goodsTotalPrice.toFixed(2) }}</span> {{
              $fanyi("元")
            }}
            （1{{ $fanyi("元") }}={{ exchangeRate }}{{ $fanyi("円") }}） <span
                class="newPrice">{{ (goodsTotalPrice * exchangeRate).toFixed(0) }}</span> {{
              $fanyi("円")
            }}
          </div>
        </div>
        <div class="buttonGroup">
          <button @click="holdDialogVisible=true">{{ $fanyi('一時保存') }}</button>
          <button @click="OrdersSubmitted('send')">{{ $fanyi('注文を提出') }}</button>
        </div>
      </div>
      <div class="addCartGoodsToOrderContainer">
        <!--      购物车追加-->
        <el-dialog
            :title="$fanyi('カードから追加')"
            :visible.sync="addCartGoodsToOrderDialogVisible"
            width="1408px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            center>
          <cart @cartAddGoodsToOrder="cartAddGoodsToOrder" :key="timer" :ids="ids"/>
        </el-dialog>
        <!--      商品庫追加-->
        <el-dialog
            :title="$fanyi('商品庫から追加')"
            :visible.sync="addInventoryGoodsToOrderDialogVisible"
            width="1462px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            center>
          <inventory @inventoryAddGoodsToOrder="inventoryAddGoodsToOrder" :key="timer" :ids="ids"/>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import HomePageTop from "@/components/head/HomePageTop";
import cart from './components/cart'
import inventory from './components/inventory'
import SpecialNoteVue from './newCom/SpecialNote'
import editTag from './components/editTag'
import saveOption from './components/excelSaveOption'
import goodsAttributeEdit from './components/goodsAttributeEdit'
import newOption from "@/views/UserCenter/views/userOrder/views/components/newOption.vue";
import {mapState} from "vuex";

export default {
  name: "temporaryOrderDetailsPage",
  components: {
    HomePageTop,
    cart,
    inventory,
    SpecialNoteVue,
    editTag,
    saveOption,
    newOption,
    goodsAttributeEdit
  },
  data() {
    return {
      datas: {},
      newList: [],
      goodsInfo: {},
      goodsIndex: 0,
      timer: null,
      goodsDetailList: [],
      fullscreenLoading: true,
      newFullscreenLoading: true,
      deleteGoodsDialogVisible: false,
      selectOptionList: [],
      orderStatus: 'temporary',
      input: '',
      holdDialogVisible: false,
      order_detail_tag: [],
      addCartGoodsToOrderDialogVisible: false,
      addInventoryGoodsToOrderDialogVisible: false,
      newShowGoodsOptionDialogVisible: false,
      goodsTotalPrice: 0,
      goodsTotalServiceCharge: 0,
      optionTotalPrice: 0,
      RMBTotalPrice: 0,
      goodsTotalServiceChargeDetailsList: [],
      yenTotalPrice: 0,
      selectTagDialogVisible: false,
      goodsDetailDialogVisible: false,
      itemIndex: 0,
      optionPrice: 0,
      showGoodsOptionList: [],
      deepCloneNewList: [],
      showGoodsOptionDialogVisible: false,
      goodsTotalNum: 0,
      optionList: [],
      ids: '',
      checked: false,
    }
  },
  computed: {
    ...mapState({
      orderFileList: "fileList",
    }) /* 推荐 */,
    exchangeRate() {
      let exchangeRate = localStorage.getItem('exchangeRate');
      return Number(exchangeRate)
    }
  },
  created() {
    if (window.name !== '') {
      let val = JSON.parse(window.name);
      this.datas = this.$fun.deepClone(val);
    } else {
      this.datas = this.$fun.deepClone(this.$store.state.excelOrderPreviewInfo);
    }
    this.newOrder();
    this.$store.commit("updateClient_remark", {text: ''});
    this.orderStatus = 'temporary';
  },
  methods: {
    // 提交或临时保存
    OrdersSubmitted(type) {
      if (this.newList.length > 100) {
        return this.$message(this.$fanyi("一个订单最多添加100个商品"));
      }
      let dataList = [];
      let str = '';
      let sku_id = '';
      let spec_id = '';
      let detailErrorStatus = false;
      let detailErrorText = '';
      this.newList.forEach((item, itemIndex) => {
        str = '';
        if (item.detail != null) {
          item.detail.forEach((item) => {
            str += item.value + '㊖㊎'
          })
          str = str.substr(0, str.length - 2);
          if (item.goodsInfo != undefined) {
            if (item.goodsInfo.goodsInventory > 0) {
              item.goodsInfo.goodsInventory.forEach((goodsInventoryItem) => {
                if (str === goodsInventoryItem.keyC) {
                  sku_id = goodsInventoryItem.valueC[0].skuId;
                  spec_id = goodsInventoryItem.valueC[0].specId
                }
              })
            }
          }
        } else {
          sku_id = item.sku_id;
          spec_id = item.spec_id;
          detailErrorStatus = true;
          detailErrorText += itemIndex + '、'
        }
        let tagList = [];
        let optionList = [];
        item.option.forEach((optionItem) => {
          if (Number(optionItem.num) > 0 && optionItem.checked === true) {
            optionItem.total = undefined
            optionList.push(optionItem)
          }
        })
        if (this.$route.query.order_sn !== undefined) {
          if (item.order_detail_tag.length > 0) {
            item.order_detail_tag.forEach((tagItem) => {
              if (tagItem.no !== '' || tagItem.goods_no !== '') {
                tagList.push({
                  type: tagItem.type,
                  no: tagItem.no,
                  goods_no: tagItem.goods_no,
                  type_translate: tagItem.type_translate
                })
              }
            })
          }
        } else {
          if (item.order_detail_tag.length > 0) {
            item.order_detail_tag.forEach((tagItem) => {
              if (tagItem.no !== '' || tagItem.goods_no !== '') {
                tagList.push({
                  type: tagItem.type,
                  no: tagItem.no,
                  goods_no: tagItem.goods_no,
                  type_translate: tagItem.type_translate
                })
              }
            })
          }
        }
        dataList.push({
          goods_id: item.goods_id,
          goods_title: item.goods_title,
          price: item.price,
          num: item.num,
          pic: item.pic,
          detail: JSON.stringify(item.detail),
          sku_id: sku_id,
          spec_id: spec_id,
          shop_id: item.shop_id,
          for_sn: item.for_sn,
          shop_name: item.shop_name,
          order_detail_tag: tagList.length > 0 ? tagList : undefined,
          option: optionList.length > 0 ? JSON.stringify(optionList) : '[]',
          from_platform: item.from_platform,
          client_remark: item.client_remark
        })
      })
      if (detailErrorStatus == true) {
        this.$confirm(
            `${detailErrorText}番の商品は、販売者の情報変更により選択された仕様が無効になりました。仕様を再選択するか、商品を注文書から削除してください。`,
            '注意',
            {
              confirmButtonText: this.$fanyi('确定'), //确定
              cancelButtonText: this.$fanyi('取消'), //取消
              type: "warning",
              center: true,
              dangerouslyUseHTMLString: true,
            }
        ).then(() => {

        }).catch(() => {

        })
        return
      }
      let datas = {
        order_file: this.$refs.SpecialNote.fileList.length > 0 ? this.$refs.SpecialNote.fileList : undefined,
        create_type: type,
        create_source: 'excel',
        order_sn: this.datas.order_sn,
        logistics_id: this.$refs.SpecialNote.selectValue,
        client_remark: this.$store.state.client_remark,
        order_detail: dataList,
      };
      this.$api.orderCreate(datas).then((res) => {
        if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
        this.$message.success(this.$fanyi(res.msg))
        // 假如点击的是临时保存按钮
        this.$store.commit("emptyFileList", {length: 0});
        this.$store.commit("setExcelOrderPreviewInfo", {})
        setTimeout(() => {
          if (type == 'save') {
            this.$fun.routerToPage("/user/order?status=temporary");
          }
          // 假如点击的是提出按钮
          if (type == 'send') {
            this.$fun.routerToPage("/user/order?status=offer");
          }
        }, 1000)
      });
    },
    //写真鼠标移入
    mirrorMouseenter(listIndex) {
      this.newList[listIndex].mirrorLeftIcon = true;
      this.goodsIndex = listIndex;
      this.$forceUpdate();
    },
    //写真鼠标移出
    mirrorMouseleave(listIndex) {
      this.newList[listIndex].mirrorLeftIcon = false;
      this.$forceUpdate();
    },
    //商品详细鼠标移入
    goodsMouseenter(listIndex) {
      this.newList[listIndex].goodsLeftIcon = true;
      this.$forceUpdate();
    },
    //商品详细鼠标移出
    goodsMouseleave(listIndex) {
      this.newList[listIndex].goodsLeftIcon = false;
      this.$forceUpdate();
    },
    //option详细鼠标移入
    priceMouseenter(listIndex) {
      this.newList[listIndex].priceLeftIcon = true;
      this.$forceUpdate();
    },
    //option详细鼠标移出
    priceMouseleave(listIndex) {
      this.newList[listIndex].priceLeftIcon = false;
      this.$forceUpdate();
    },
    //标签编号鼠标移入
    optionsMouseenter(listIndex) {
      this.newList[listIndex].optionsLeftIcon = true;
      this.$forceUpdate();
    },
    //标签编号鼠标移出
    optionsMouseleave(listIndex) {
      this.newList[listIndex].optionsLeftIcon = false;
      this.$forceUpdate();
    },
    //确认是否展示删除商品弹窗
    showDeleteGoodsDialogVisible() {
      let count = 0;
      if (this.checked === true) {
        count = this.newList.length
      } else {
        for (let i = 0; i < this.newList.length; i++) {
          if (this.newList[i].checked === true) {
            count++
            break;
          }
        }
      }
      if (count === 0) {
        this.$message.error(this.$fanyi('请至少选择一个商品'));
        return
      }
      this.deleteGoodsDialogVisible = true;
    },
    //商品链接转商品ID，在订单中搜索该id
    linkSearch() {
      if (this.input !== '') {
        let arr = [];
        this.$api.goodsUrlToGoodsId({goods_url: this.input}).then((res) => {
          this.newList.forEach((item) => {
            if (item.goods_id === res.data.goods_id) {
              arr.push(item)
            }
          })
          if (arr.length === 0) {
            this.$message.error(this.$fanyi("订单中未找到该商品"))
          } else {
            this.newList = arr;
          }
        });
      }
    },
    //重置商品链接搜索
    resetLinkSearch() {
      this.input = '';
      this.newList = this.$fun.deepClone(this.deepCloneNewList);
    },
    //弹出从购物车追加商品
    showAddCartGoodsToOrderDialog(val) {
      let str = ''
      this.newList.forEach((item) => {
        if (item.id != undefined) {
          str += item.id + ','
        }
      })
      str = str.substr(0, str.length - 1);
      this.timer = new Date().getTime();
      this.ids = str;
      val === 1 ? this.addCartGoodsToOrderDialogVisible = true : this.addInventoryGoodsToOrderDialogVisible = true;
    },
    //获取商品的信息
    getItemMessage(item) {
      this.goodsInfo = item;
    },
    //图片上传
    beforeAvatarUpload(file) {
      const isJPG = ["image/jpeg", "image/png", "image/jpg"].indexOf(file.type) != -1;
      if (!isJPG) {
        this.$message.error(this.$fanyi("请上传图片"));
        return isJPG;
      }
      this.loading = this.$loading({
        lock: true,
        text: this.$fanyi("写真上传中"),
        spinner: "el-icon-loading fontSize50",
        background: "rgba(255, 255, 255, 0.7)",
      });
    },
    // 订单预览
    newOrder() {
      this.$api.internationalLogisticsTransportation().then((res) => {
        res.data.forEach((item) => {
          if (item.name == 'RW船便' || item.name == 'Raku-DQ') {
            item.name += '―定額会員限定ルート'
          }
          if (item.id == 14) {
            this.$refs.SpecialNote.selectValue = item.id
          }
        })
        this.$store.commit("getLogisticsTransportation", {logisticsTransportation: res.data});
        this.$api.optionList({
          need_desc: true
        }).then((res) => {
          res.data.forEach((item) => {
            item.checked = false;
            item.total = 0;
          })
          this.optionList = res.data;
          let arr = [];
          this.$store.commit("updateClient_remark", {text: this.datas.order_remark});
          this.datas.order_data.forEach((item) => {
            item.goods.forEach((items, goodsIndex) => {
              items.checked = false;
              if (typeof items.detail === 'string') {
                items.detail = JSON.parse(items.detail);
              }
              items.mirrorLeftIcon = false;
              items.goodsLeftIcon = false;
              items.priceLeftIcon = false;
              items.optionsLeftIcon = false;
              items.tagList = [];
              items.for_sn = '';
              if (items.id == undefined) {
                items.id = goodsIndex
              }
              if (items.option != null) {
                if (items.option.length !== this.optionList.length) {
                  let newOptionList = this.$fun.deepClone(this.optionList);
                  items.option.forEach((item1) => {
                    newOptionList.forEach((optionItem, index) => {
                      if (item1.id === optionItem.id) {
                        optionItem.num = item1.num;
                        optionItem.checked = false;
                        optionItem.price = item1.price;
                      } else if (item1.id == undefined && index === newOptionList.length - 1) {
                        newOptionList.push(item1);
                      }
                    })
                  })
                  items.option = this.$fun.deepClone(newOptionList);
                }
                items.option.forEach((optionItem) => {
                  if (Number(optionItem.num) > 0) {
                    items.optionChecked = true;
                    optionItem.checked = true;
                    if (optionItem.price_type === 1) {
                      optionItem.total = (optionItem.num * Number(items.price)) * (Number(optionItem.price) / 100);
                      items.optionPrice += (optionItem.num * Number(items.price)) * (Number(optionItem.price) / 100);
                      items.optionCheckedNum++
                    }
                    if (optionItem.price_type === 0) {
                      optionItem.total = Number(optionItem.num) * Number(optionItem.price);
                      items.optionPrice += Number(optionItem.num) * Number(optionItem.price);
                      items.optionCheckedNum++
                    }
                  }
                })
              } else {
                items.option = this.$fun.deepClone(this.optionList);
                items.optionChecked = false
              }
              items.totalPrice = 0;
              items.goodsServiceCharge = 0;
              items.optionPrice = 0;
              items.priceRanges = [];
              arr.push(items);
            })
          })
          this.fullscreenLoading = false;
          this.newList = arr;
          this.deepCloneNewList = this.$fun.deepClone(this.newList);
          this.commodityTotal();
          if (this.datas.message != undefined) {
            if (this.datas.message.length > 0) {
              let str = '<div>';
              for (let i = 0; i < this.datas.message.length; i++) {
                str += `<div>${this.datas.message[i]}</div>`
                if (i === this.datas.message.length - 1) {
                  str += `</div>`
                  this.$confirm(
                      `${str}`,
                      '提示',
                      {
                        confirmButtonText: this.$fanyi('确定'), //确定
                        cancelButtonText: this.$fanyi('取消'), //取消
                        dangerouslyUseHTMLString: true,
                        type: "warning",
                        center: true,
                      }
                  )
                  .then(() => {

                  })
                  .catch(() => {
                  });
                }
              }
            }
          }
        })
      });
    },
    hideGoodsDetailDialogVisible(type) {
      this.goodsDetailDialogVisible = false;
    },
    //获取商品tag
    getGoodsTag(item, index) {
      this.goodsIndex = index;
      this.goodsInfo = item;
      this.selectTagDialogVisible = true;
      this.$api.getOrderTagList({
        order_detail_id: item.id
      }).then((res) => {
        let list = [];
        res.data.forEach((item) => {
          list.push({
            value: item.type,
            label: item.type_translate
          })
        })
        if (item.order_detail_tag.length === 0) {
          let order_detail_tag = [];
          order_detail_tag.push({
            no: '',
            goods_no: '',
            list: list,
            type: 'FBA'
          })
          setTimeout(() => {
            this.newFullscreenLoading = false;
            this.order_detail_tag = this.$fun.deepClone(order_detail_tag);
            this.$refs.editTag.value = order_detail_tag[0].id
          }, 500)
        } else {
          item.order_detail_tag.forEach((items) => {
            items.list = list
          })
          this.$refs.editTag.value = item.order_detail_tag[0].id;
          this.order_detail_tag = this.$fun.deepClone(item.order_detail_tag);
          setTimeout(() => {
            this.newFullscreenLoading = false;
          }, 500)
        }
      });
    },
    //保存商品的标签编号
    saveGoodsTag(msg) {
      this.newList.forEach((item, index) => {
        if (index === this.goodsIndex) {
          let arr = [];
          msg.forEach((msgItem) => {
            if (msgItem.no !== '' || msgItem.goods_no !== '') {
              for (let i = 0; i < msgItem.list.length; i++) {
                if (msgItem.list[i].value === msgItem.type) {
                  msgItem.type_translate = msgItem.list[i].label;
                  break;
                }
              }
              arr.push(msgItem)
            }
          })
          item.order_detail_tag = arr;
        }
      })
      this.selectTagDialogVisible = false;
      this.order_detail_tag = [];
      this.deepCloneNewList = this.$fun.deepClone(this.newList);
    },
    //追加购物车商品的商品至订单
    cartAddGoodsToOrder(msg) {
      let count = this.newList.length;
      msg.forEach((item) => {
        count++;
        item.checked = false;
        if (typeof item.detail === 'string') {
          item.detail = JSON.parse(item.detail);
        }
        item.is_client_send_data = {
          num: item.num,
          price: item.price
        }
        item.mirrorLeftIcon = false;
        item.goodsLeftIcon = false;
        item.priceLeftIcon = false;
        item.optionsLeftIcon = false;
        item.order_detail_tag = [];
        item.for_sn = '';
        if (item.goodsInfo != undefined) {
          if (item.goodsInfo.specification.length > 0) {
            item.goodsInfo.specification.forEach((specificationItem) => {
              specificationItem.valueC.forEach((valueCItem, valueCIndex) => {
                item.detail.forEach((detailItem) => {
                  if (detailItem.key === specificationItem.keyC && detailItem.value === valueCItem.name) {
                    specificationItem.active = valueCIndex
                  }
                })
              })
            })
          }
        }
        item.No = count;
        item.option = this.$fun.deepClone(this.optionList);
        item.optionChecked = false;
        item.order_detail_tag = [];
        this.newList.push(item);
      })
      this.deepCloneNewList = this.$fun.deepClone(this.newList);
      this.addCartGoodsToOrderDialogVisible = false;
      this.addInventoryGoodsToOrderDialogVisible = false;
      this.commodityTotal();
    },
    //追加商品库商品的商品至订单
    inventoryAddGoodsToOrder(msg) {
      let count = this.newList.length;
      msg.forEach((item) => {
        count++;
        item.checked = false;
        if (typeof item.detail === 'string') {
          item.detail = JSON.parse(item.detail);
        }
        item.is_client_send_data = {
          num: item.num,
          price: item.price
        }
        item.mirrorLeftIcon = false;
        item.goodsLeftIcon = false;
        item.priceLeftIcon = false;
        item.optionsLeftIcon = false;
        item.order_detail_tag = item.inventory_tag;
        item.pic = item.img_url;
        if (item.goodsInfo != undefined) {
          if (item.goodsInfo.specification.length > 0) {
            item.goodsInfo.specification.forEach((specificationItem) => {
              specificationItem.valueC.forEach((valueCItem, valueCIndex) => {
                item.detail.forEach((detailItem) => {
                  if (detailItem.key === specificationItem.keyC && detailItem.value === valueCItem.name) {
                    specificationItem.active = valueCIndex
                  }
                })
              })
            })
          }
        }
        item.from_platform = item.shop_type;
        item.num = 1;
        item.No = count;
        item.option.length === 0 ? item.optionChecked = false : item.optionChecked = true;
        if (item.option.length !== this.optionList.length) {
          let newOptionList = this.$fun.deepClone(this.optionList);
          item.option.forEach((item1) => {
            newOptionList.forEach((optionItem) => {
              if (item1.id === optionItem.id) {
                optionItem.num = item1.num;
                optionItem.checked = item1.checked;
              }
            })
          })
          item.option = newOptionList;
        }
        item.order_detail_tag = item.inventory_tag;
        this.newList.push(item);
      })
      this.deepCloneNewList = this.$fun.deepClone(this.newList);
      this.addCartGoodsToOrderDialogVisible = false;
      this.addInventoryGoodsToOrderDialogVisible = false;
      this.commodityTotal();
    },
    //图片上传成功
    handleAvatarSuccess(res, file) {
      this.newList.forEach((item, index) => {
        if (this.goodsIndex === index) {
          item.pic = res.data;
          this.loading.close();
        }
      })
      this.deepCloneNewList = this.$fun.deepClone(this.newList);
    },
    // 全选
    allChoice() {
      if (this.checked) {
        this.newList.forEach((item) => {
          item.checked = true;
        });
      } else {
        this.newList.forEach((item) => {
          item.checked = false;
        });
      }
      this.$forceUpdate();
    },
    // 删除商品
    delOrder() {
      this.deleteGoodsDialogVisible = false;
      if (this.checked === true) {
        this.newList = [];
        this.checked = false;
      } else {
        for (let i = this.newList.length - 1; i >= 0; i--) {
          if (this.newList[i].checked === true) {
            this.newList.splice(i, 1);
          }
        }
      }
      this.commodityTotal();
      this.deepCloneNewList = this.$fun.deepClone(this.newList);
    },
    // 是否全选中
    isCheckedAll() {
      var flag = this.newList.every((item) => item.checked == true);
      if (flag == true) {
        this.checked = true;
      } else {
        this.checked = false;
      }
      this.$forceUpdate();
    },
    // 单选
    radio(i) {
      let flag = this.newList[i].checked == true;
      if (flag) {
        this.newList[i].checked = true;
      } else {
        this.newList[i].checked = false;
      }
      this.isCheckedAll();
      this.$forceUpdate();
    },
    //修改商品的规格
    getGoodsDetail(detail, item) {
      this.goodsIndex = item.id;
      if (item.goodsInfo == undefined) {
        this.$api.getGoodsAttribute({
          shop_type: item.from_platform,
          goods_id: item.goods_id
        }).then((res) => {
          if (res.data.length > 0) {
            item.goodsInfo = {
              goodsInventory: this.$fun.deepClone(res.data[0].goodsInventory),
              specification: this.$fun.deepClone(res.data[0].specification),
            }
            res.data[0].specification.forEach((specificationItem) => {
              if (detail != null) {
                foo:for (let j = 0; j < detail.length; j++) {
                  for (let i = 0; i < specificationItem.valueC.length; i++) {
                    if (specificationItem.keyC === detail[j].key && specificationItem.valueC[i].name === detail[j].value) {
                      specificationItem.active = i;
                      specificationItem.oldActive = this.$fun.deepClone(i);
                      break foo;
                    } else {
                      specificationItem.active = 0;
                      specificationItem.oldActive = this.$fun.deepClone(0);
                    }
                  }
                }
              }
            })
          } else {
            item.goodsInfo = {
              goodsInventory: [],
              specification: [],
            }
          }
          this.timer = new Date().getTime();
          this.goodsInfo = item;
          this.goodsDetailList = res.data[0].specification;
          this.goodsDetailDialogVisible = true;
        })
      } else {
        if (item.goodsInfo.specification.length > 0) {
          item.goodsInfo.specification.forEach((specificationItem) => {
            foo:for (let j = 0; j < detail.length; j++) {
              for (let i = 0; i < specificationItem.valueC.length; i++) {
                if (specificationItem.keyC === detail[j].key && specificationItem.valueC[i].name === detail[j].value) {
                  specificationItem.active = i;
                  specificationItem.oldActive = this.$fun.deepClone(i);
                  break foo;
                } else {
                  specificationItem.active = 0;
                  specificationItem.oldActive = this.$fun.deepClone(0);
                }
              }
            }
          })
        }
        this.timer = new Date().getTime();
        this.goodsInfo = item;
        this.goodsDetailList = item.goodsInfo.specification;
        this.goodsDetailDialogVisible = true;
      }
    },
    //展示商品的附加服务
    showGoodsOption(item, itemIndex) {
      this.goodsInfo = item;
      this.itemIndex = item.id;
      this.optionPrice = 0;
      item.option.forEach((optionItem) => {
        this.optionPrice = Number(this.optionPrice);
        if (optionItem.price_type === 1 && optionItem.checked === true) {
          optionItem.total = ((item.num * Number(item.price)) * (Number(optionItem.price) / 100)).toFixed(2);
          this.optionPrice += ((item.num * Number(item.price)) * (Number(optionItem.price) / 100)).toFixed(2);
        }
        if (optionItem.price_type !== 1 && optionItem.checked === true) {
          this.optionPrice += optionItem.num * Number(optionItem.price);
          optionItem.total = optionItem.num * Number(optionItem.price);
        }
      })
      this.goodsIndex = itemIndex;
      this.timer = new Date().getTime();
      this.showGoodsOptionList = this.$fun.deepClone(item.option);
      this.newShowGoodsOptionDialogVisible = true
    },
    //输入框取整
    numTrunc(item) {
      item.num = this.$fun.priceReplace(item.num)
      this.commodityTotal();
    },
    // 选中商品数量总数、总价
    commodityTotal() {
      this.goodsTotalPrice = 0;
      this.goodsTotalServiceCharge = 0;
      this.goodsTotalServiceChargeDetailsList = [];
      this.RMBTotalPrice = 0;
      this.yenTotalPrice = 0;
      this.optionTotalPrice = 0;
      this.goodsTotalNum = 0;
      this.newList.forEach((item, i) => {
        let str = '';
        if (item.detail != null) {
          item.detail.forEach((detailItem) => {
            str += detailItem.value + '㊖㊎';
          })
        }
        str = str.substr(0, str.length - 2);
        if (item.goodsInfo != undefined) {
          if (item.goodsInfo.goodsInventory > 0) {
            item.goodsInfo.goodsInventory.forEach((goodsInventoryItem) => {
              if (str === goodsInventoryItem.keyC) {
                goodsInventoryItem.valueC.forEach((valueItem) => {
                  if (item.num >= valueItem.startQuantity) {
                    item.price = valueItem.price
                  }
                })
              }
            })
          }
        }
        item.optionPrice = 0;
        item.optionCheckedNum = 0;
        item.option.forEach((optionItem) => {
          if (optionItem.checked === true && Number(optionItem.num) > 0 && optionItem.price_type === 0) {
            item.optionPrice += optionItem.num * optionItem.price;
            this.optionTotalPrice += optionItem.num * optionItem.price;
            item.optionCheckedNum++
          }
          if (optionItem.checked === true && optionItem.price_type === 1) {
            item.optionPrice += (item.num * Number(item.price)) * (Number(optionItem.price) / 100);
            this.optionTotalPrice += (item.num * Number(item.price)) * (Number(optionItem.price) / 100);
            item.optionCheckedNum++
          }
        })
        item.goodsServiceCharge = (item.num * Number(item.price)) * item.service_rate
        this.goodsTotalServiceCharge += item.goodsServiceCharge;
        item.totalPrice = item.optionPrice + (item.num * Number(item.price)) + item.goodsServiceCharge;
        this.goodsTotalPrice += Number(item.price) * item.num
        this.goodsTotalServiceChargeDetailsList.push({
          No: i + 1,
          service_rate: this.$fun.returnServiceRate(item.service_rate),
          value: (Number(item.price) * item.num) * item.service_rate
        })
        this.goodsTotalNum += Number(item.num)
      });
      this.RMBTotalPrice = Number(this.goodsTotalPrice + this.optionTotalPrice + this.goodsTotalServiceCharge);
      this.yenTotalPrice = Number(this.RMBTotalPrice * this.exchangeRate)
    },
    stopScroll(evt) {
      evt = evt || window.event;
      if (evt.preventDefault) {
        // Firefox
        evt.preventDefault();
        evt.stopPropagation();
      } else {
        // IE
        evt.cancelBubble = true;
        evt.returnValue = false;
      }
      return false;
    },
    //保存商品属性编辑
    saveGoodsDetail(type, arr) {
      let str = '';
      let newDetailList = [];
      let pic = '';
      let status = false
      if (type === 1) {
        arr.forEach((item) => {
          if (item.valueC[item.active].picUrl !== '') {
            pic = item.valueC[item.active].picUrl
          }
          newDetailList.push({
            key: item.keyC,
            value: item.valueC[item.active].name
          })
          str += item.valueC[item.active].name + '㊖㊎'
        })
        str = str.substr(0, str.length - 2);
        this.goodsInfo.goodsInfo.goodsInventory.forEach((item) => {
          if (str === item.keyC && (this.goodsInfo.num > item.startQuantity || this.goodsInfo.num === item.startQuantity)) {
            this.goodsInfo.price = item.price;
          }
        })
      } else {
        var sarr = arr.sort();
        for (let i = 0; i < sarr.length; i++) {
          if (i !== sarr.length - 1) {
            if (sarr[i].key == sarr[i + 1].key) {
              status = true;
              break;
            }
          }
        }
      }
      if (status === true) {
        this.$message.error('チェック便宜上、属性制限しております。必要な場合では担当者までご連絡くださいませ。');
        return
      }
      this.newList.forEach((item, index) => {
        if (this.goodsIndex === index) {
          item.detail = type === 1 ? newDetailList : arr;
          if (pic !== '') {
            item.pic = pic;
          }
        }
      })
      this.goodsDetailDialogVisible = false;
      this.commodityTotal();
      this.deepCloneNewList = this.$fun.deepClone(this.newList);
    },
    //保存商品的附加服务
    saveGoodsOption(msg) {
      let count = 0;
      this.optionTotalPrice = 0;
      let selectOptionList = [];
      this.newList.forEach((item) => {
        count = 0;
        item.optionPrice = 0;
        if (item.checked === true || this.itemIndex === item.id) {
          msg.forEach((msgItem) => {
            if (msgItem.checked === true && msgItem.num > 0) {
              item.optionCheckedNum++;
            }
          })
          item.option = this.$fun.deepClone(msg);
        }
        item.option.forEach((optionItem) => {
          if (optionItem.checked === true && Number(optionItem.num) > 0 && optionItem.price_type === 0) {
            if (optionItem.num > item.num) {
              optionItem.num = item.num
            }
            item.optionPrice += optionItem.num * optionItem.price;
            this.optionTotalPrice += optionItem.num * optionItem.price;
            optionItem.total = optionItem.num * optionItem.price;
            count++
            selectOptionList.push(this.$fun.deepClone(optionItem));
          }
          if (optionItem.checked === true && optionItem.price_type === 1) {
            if (optionItem.num > item.num) {
              optionItem.num = item.num
            }
            item.optionPrice += (optionItem.num * Number(item.price)) * (Number(optionItem.price) / 100);
            this.optionTotalPrice += (optionItem.num * Number(item.price)) * (Number(optionItem.price) / 100);
            optionItem.total = (optionItem.num * Number(item.price)) * (Number(optionItem.price) / 100);
            count++
            selectOptionList.push(this.$fun.deepClone(optionItem));
          }
        })
        count > 0 ? item.optionChecked = true : item.optionChecked = false
      })
      let end = [];
      end.push(selectOptionList[0]);
      if (selectOptionList.length > 0) {
        for (let i = 1; i < selectOptionList.length; i++) {
          selectOptionList[i].num = Number(selectOptionList[i].num);
          let j = 0
          for (; j < end.length; j++) {
            // 6. 判断两个数组中的id是否相同
            if (selectOptionList[i].id === end[j].id) {
              // 7.相等就将我们比较的对象 `arr[i]` 存储到  相同id对象的list 中
              end[j].num = Number(end[j].num);
              end[j].num += selectOptionList[i].num;
              end[j].total += selectOptionList[i].total
              // *** 8.这里要理解，我们每次遍历，其实都是处理  `arr[i]` ，而上面的逻辑已经处理了，所以终止后续的循环 break;
              break;
            }
          }
          if (j === end.length) {
            end.push(selectOptionList[i])
          }
        }
      }
      if (end[0] != undefined) {
        this.selectOptionList = end;
      } else {
        this.selectOptionList = [];
      }
      this.commodityTotal();
      this.deepCloneNewList = this.$fun.deepClone(this.newList);
      this.optionPrice = 0;
      this.showGoodsOptionDialogVisible = false;
    },
    //输入框校验
    newChannelInputLimit(e) {
      let key = e.key;
      // 不允许输入'e'、'.'、'。'、'-'
      if (key === "e" || key === "." || key === "。" || key === "-") {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    handleClose(done) {
      this.$confirm(`${this.$fanyi("确认关闭")}？`)
      .then(_ => {
        done();
      })
      .catch(_ => {
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../../css/mixin.scss";
@import "css/orderDetails.scss";

.mirrorActive {
  border: 1px dashed #B4272B !important;
}

.newMirror {
  margin-top: 22px;
  flex-direction: column !important;
  flex-wrap: nowrap !important;
  align-items: center !important
}

.priceLeftIconContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .riceLeftIconHover {
    width: 26px;
    height: 16px;
    background: #B4272B;
    justify-content: center;

    img {
      width: 12px;
      height: 12px
    }
  }
}

.optionHiddenContainer {
  height: 63px !important;
  overflow: hidden;
}

.orderTagContainer {
  display: flex;
  flex-direction: column;
  height: 79px;
  margin: 0 26px;
  overflow: hidden;
  width: 161px;
  text-align: left
}

.orderTagPopoverShowContainer {
  display: flex;
  flex-direction: column;
  width: 300px;
  text-align: left
}

input[type="checkbox"]:checked {
  background: #4A91E9;
  border: none;
}
</style>
